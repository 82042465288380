<template>
  <v-dialog
    v-model="isOpen"
    fullscreen
  >
    <v-card>
      <v-card-title
        class="full-width-card-title"
      >
      <v-icon flat @click="clicked">close</v-icon>API Log
      </v-card-title>
      <v-card-text>
        <ul>
          <li v-for="(item, i) in getAllResponses" :key="i">
            {{ item.label }} ({{ item.key }}) -- {{ item.responseTime }} (ms)
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PerformanceLog',
  props: {
    open: Boolean,
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    ...mapGetters('perf', [
      'getAllResponses',
    ]),
  },
  watch: {
    open(val) {
      this.isOpen = val;
    },
  },
  methods: {
    clicked() {
      this.isOpen = false;
      this.$emit('clicked');
    },
  },
};
</script>
